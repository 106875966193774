<template>
  <div>
    <form style="display: none" method="post" id="exportForm"
          :action="`${axios.defaults.baseURL}/reports/downloadSentMessagesReport`">
      <input name="params" type="hidden" id="exportParams"/>
      <input name="body" type="hidden" id="exportBody"/>
      <input name="__sg_client_token" type="hidden" :value="$store.getters.token"/>
    </form>
    <!--    <el-row v-if="!isCallView">-->
    <!--      <el-col :span="24" style="padding-left: 20px">-->
    <!--        <h4>Подробный отчет</h4>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <!--    <h4 class="report-parameters-title">Параметры отчета</h4>-->
    <el-row :gutter="40">
      <el-col :md="12" :xs="24">
        <TimeSearch
            :full-width-select="true"
            :free-date-time="true"
            :month="true"
            :short-date="true"
            ref="timeSearch"
        />
      </el-col>
      <el-col :md="12" :xs="24">
        <slot name="admin-settings" :onUsersChange="setUsersToFilter"
              :onSenderTypeChange="val => extraSearchParams.senderType = val"></slot>
        <el-form label-position="top" style="margin-bottom: 20px">
          <el-form-item label="Поиск по" style="margin-bottom: 10px">
            <el-input v-model="freeSearchToken"/>
          </el-form-item>
          <el-checkbox-group v-model="searchTokenIn">
            <el-checkbox label="text" v-if="!isCallView">тексту</el-checkbox>
            <el-checkbox label="sender" v-if="!isCallView">отправителю</el-checkbox>
            <el-checkbox label="receiver">получателю</el-checkbox>
          </el-checkbox-group>
        </el-form>
        <!--        <el-divider></el-divider>-->


        <el-form label-position="top" inline>
          <el-form-item label="Система отправки" v-if="!isCallView">
            <el-select v-model="sendingSystem">
              <el-option value="" label="Все"></el-option>
              <el-option value="web" label="WEB"></el-option>
              <el-option value="http" label="HTTP"></el-option>
              <el-option value="smpp" label="SMPP"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Статус" v-if="!isCallView">
            <el-select v-model="searchByStatus">
              <el-option value="" label="Все"></el-option>
              <el-option value="0" label="В очереди на отправку"></el-option>
              <el-option value="1" label="Отправлено оператору"></el-option>
              <el-option value="2" label="Доставлено"></el-option>
              <el-option value="3" label="Не доставлено"></el-option>
              <el-option value="4" label="Просрочено"></el-option>
              <el-option value="2_1" label="Прочитано (VK/OK)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Статус" v-if="isCallView">
            <el-select v-model="searchByStatus">
              <el-option value="" label="Все"></el-option>
              <el-option value="1" label="В процессе вызова"></el-option>
              <el-option value="2" label="Успешный вызов"></el-option>
              <el-option value="3" label="Ошибка вызова"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Тип сообщения" v-if="!isCallView">
            <el-select v-model="searchByMessageType">
              <el-option-group>
                <el-option value="0" label="Все"></el-option>
                <el-option value="6" label="VK/OK"></el-option>
                <el-option value="7" label="Telegram"></el-option>
              </el-option-group>
              <el-option-group label="СМС">
                <el-option value="1" label="Все СМС"></el-option>
                <el-option value="4_0" label="Сервисные СМС"></el-option>
                <el-option value="4_1" label="Рекламные СМС (без шаблона)"></el-option>
                <el-option value="4_7" label="Рекламные СМС (с шаблоном)"></el-option>
                <el-option value="4_2" label="Информационные СМС"></el-option>
                <el-option value="4_3" label="Транзакционные СМС"></el-option>
                <el-option value="4_4" label="Авторизационные СМС"></el-option>
                <el-option value="4_5" label="Мультиподпись СМС"></el-option>
                <el-option value="4_6" label="Международные отправители"></el-option>
              </el-option-group>
<!--              <el-option-group label="Viber">-->
<!--                <el-option value="5" label="Все Viber"></el-option>-->
<!--                <el-option value="5_1" label="Viber сервисный"></el-option>-->
<!--              </el-option-group>-->

            </el-select>
          </el-form-item>
          <el-form-item label="Тип звонка" v-if="isCallView">
            <el-select v-model="searchByMessageType">
              <el-option value="0" label="Все"></el-option>
              <el-option value="1" label="Коды в номере"></el-option>
              <el-option value="2" label="Голосовые сообщения"></el-option>
            </el-select>
          </el-form-item>
        </el-form>


      </el-col>

    </el-row>
    <el-button type="success" plain @click="getReport" :loading="reportLoading"
               style="margin-top: 20px; margin-bottom:60px">Применить
    </el-button>
    <el-row v-show="reportReady">
      <el-col :span="24">
        <TableSpinner :loading="dataLoading" :rows="10">
          <div style="display: flex;align-items: center;justify-content: space-between; margin-bottom : 40px">

            <span style="color: var(--el-text-color-regular)">Количество адресатов: {{ totalRows }}<br/>Количество сообщений: {{ totalMessages }}</span>
            <el-button text class="text-button" @click="exportReportData"
                       style="padding: 0px; min-height: auto;height: auto !important">
              <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать
            </el-button>

          </div>
          <el-table :data="reportData" style="width: 100%" cell-class-name="small-padding-table-cell">
            <el-table-column label="Время / Система / IP" min-width="120">
              <template #default="scope">
                <span>{{ formatDateTime(scope.row.date_added) }}</span><br/>
                <span>{{ scope.row.sent_through }}</span><br/>
                <span>{{ scope.row.ip }}</span>
              </template>
            </el-table-column>
            <slot name="admin-message-id"></slot>
            <el-table-column label="Пользователь" width="130">
              <template #default="scope">
                {{ scope.row.subuser_system_id || scope.row.user_system_id }}
              </template>
            </el-table-column>
            <el-table-column label="Тип" :width="isCallView ? 100 : 90">
              <template #default="scope">
                {{ messageType(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column label="Адресат" width="120">
              <template #default="scope">
                {{ messageTarget(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column label="Оператор" width="120" prop="operator_name"/>
            <el-table-column label="Отправитель / Сообщение" min-width="200">
              <template #default="scope">
                <span v-html="senderAndText(scope.row)"></span>
              </template>
            </el-table-column>
            <el-table-column label="Кол-во." width="100">
              <template #default="scope">
                {{ messageLength(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column label="Цена" width="100">
              <template #default="scope">
                {{ formatNumber(scope.row.price) }}
              </template>
            </el-table-column>
            <el-table-column :label="isCallView ? 'Вызов абонента' : 'Статус отправки'"
                             :min-width="isCallView ? 120 : 110">
              <template #default="scope">
                <span v-html="sendingStatus(scope.row)"></span>
              </template>
            </el-table-column>
            <el-table-column :label="isCallView ? 'Ответ абонента' : 'Финальный статус'"
                             :min-width="isCallView ? 120 : 110" fixed="right">
              <template #default="scope">
                <span v-html="deliveryStatus(scope.row)"></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="solo-pagination-container">
            <el-pagination
                background
                layout="sizes, prev, pager, next"
                :total="totalRows"
                class="wide-page-size"

                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :pager-count="3"
                :hide-on-single-page="true"
                @size-change="handlePageSizeChange"
                @current-change="handleCurrentPageChange"
            >
            </el-pagination>
          </div>
        </TableSpinner>

      </el-col>
    </el-row>

  </div>
</template>

<script>


import TimeSearch from "@shared/components/reports/time-search.component"
import moment from "moment";


export default {
  name: "DetailTable",
  components: {
    TimeSearch,
  },
  props: [
    "isCallView"
  ],
  computed: {},
  methods: {
    deliveryStatus(row) {
      let statusFromAggregatorStr = this.isCallView ? AGREGATOR_STATUS_LIST_CALLS[String(row.status_from_agregator)] || AGREGATOR_STATUS_LIST_CALLS.__default__ : AGREGATOR_STATUS_LIST[String(row.status_from_agregator)]
      let receivedAtStr = this.formatDateTime(row.actual_date_received || row.actual_date_sent)
      let style = ""
      if (row.status_from_agregator === 2) {
        if (row.vk_read || row.tg_read) {
          style = "color: #00AA55; font-weight: bold"
          statusFromAggregatorStr = "Прочитано"
          receivedAtStr = this.formatDateTime(row.vk_read_at || row.tg_read_at)
        } else style = "color: #006400; font-weight: bold"
      } else if (row.status_from_agregator === 3)
        style = "color: #FF9900; font-weight: bold"
      else if (row.status_from_agregator === 5)
        style = "color: #FF0000; font-weight: bold"

      statusFromAggregatorStr = `<span style="${style}">${statusFromAggregatorStr}</span>`

      return `${statusFromAggregatorStr}<br/>${receivedAtStr}`
    },
    sendingStatus(row) {
      let verbStatus = this.isCallView ? "Вызов абонента инициирован" : "отправлено оператору"
      return `${verbStatus}<br/>${this.formatDateTime(row.actual_date_sent)}`
    },
    messageType(row) {
      let baseType = this.isCallView ? CALL_TYPES[row.callType] : CHANNEL_NAMES[row.channel]
      if(!this.isCallView && row.channel === "sms" && row.is_international_name){
        baseType = "Международное"
        if(row.is_international_converted) baseType += " / конвертированное"
      }
      return baseType
    },
    messageLength(row) {
      let lng = row.smsLength || row.length
      if (row.channel === "viber" || row.channel === "sms_viber")
        lng = row.viberLength || row.length
      if (row.channel === "vk")
        lng = 1
      return lng
    },
    senderAndText(row) {
      // console.log(row)
      if (row.channel === "telegram") {
        if(!row.telegram) return ""
        if(row.telegram.dialogType === "authCode") return `<i>Код:</i>&nbsp;${row.code}`
        return (row.telegram.messages || []).map(m => {
          let t = `<pre class="text-pre">${m.text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\\n/g, '<br/>')}</pre>`
          if(m.files && m.files.length)
            t += `<br/>📎 ${m.files[0].name}`
          return t
        }).join("<hr/>")

      }
      let text = row.text || row.sms_text
      let sender = row.sender_name || row.sms_sender_name
      let originalText = row.original_text ? `<br/><i>( исх. текст: ${row.original_text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\\n/g, '<br/>')} )</i>` : ''

      if (row.channel === "vk")
        return `В соответствии с шаблоном <b>${row.vk_template}</b>`
      else if (row.channel === "call") {
        if (row.callType === "flash" || row.callType === "voiceCode")
          return `Код: <b>${row.flash_code}</b>`
        else
          return `<b>${sender}</b><br/><pre class="text-pre">${text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\\n/g, '<br/>')}</pre>`
      }// else if(row.channel ==='telegram'){
      //   return `<b>${sender}</b><br/><pre class="text-pre">${text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\\n/g, '<br/>')}</pre>`
      // }
      return `<b>${sender}</b><br/><pre class="text-pre">${text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\\n/g, "<br/>")}${originalText}</pre>`
    },
    formatDateTime(val) {
      return moment(val).utcOffset(this.$store.getters.user.utcOffset || 0).format("DD.MM.YYYY HH:mm:ss")
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
      this.getReport(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      this.getReport(false)
    },
    __getReportParams() {
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      return {
        date_from: dateFilter.from.format("DD.MM.YYYY HH:mm"),
        date_to: dateFilter.to.format("DD.MM.YYYY HH:mm"),
        pageSize: this.pageSize,
        currentPage: this.currentPage - 1,
        searchTokenIn: this.searchTokenIn.join(","),
        sendingSystem: this.sendingSystem,
        freeSearchToken: this.freeSearchToken,
        searchByStatus: this.searchByStatus,
        searchByMessageType: this.searchByMessageType,
        isCall: this.isCallView
      }
    },
    getReport(doCount = false) {
      if (doCount) {
        this.reportLoading = true
        this.reportReady = false
      } else {
        this.dataLoading = true
      }
      let params = this.__getReportParams()
      this.axios.post("/reports/getSentMessagesDetailReport", this.extraSearchParams, {params})
          .then(resp => {
            if (doCount) {
              this.reportLoading = false
              this.reportReady = true
            } else {
              this.dataLoading = false
            }
            this.reportData = resp.data.records
            this.currentPage = 1
          })
      if (doCount)
        this.axios.post("/reports/getSentMessagesDetailReportCount", this.extraSearchParams, {params})
            .then(resp => {
              this.totalRows = resp.data.count ? resp.data.count.phones : 0
              this.totalMessages = resp.data.count ? resp.data.count.messages : 0
            })
    },
    exportReportData() {
      document.getElementById("exportParams").value = JSON.stringify(this.__getReportParams())
      document.getElementById("exportBody").value = JSON.stringify(this.extraSearchParams)
      document.getElementById("exportForm").submit()
    },
    setUsersToFilter(users) {
      this.extraSearchParams.users = users
    },
    resetAdminFilter() {
      this.extraSearchParams.users = []
      this.extraSearchParams.senderType = ""
    },
    messageTarget(msg){
      if(msg.channel === 'telegram' && !msg.phone) return `@${msg.username}`
      return msg.patched_phone || msg.phone
    }
  },
  data() {
    return {
      reportReady: false,
      reportLoading: false,
      dataLoading: false,
      reportData: [],
      searchTokenIn: [],
      sendingSystem: "",
      freeSearchToken: "",
      searchByStatus: "",
      searchByMessageType: "0",
      currentPage: 1,
      pageSize: 10,

      totalRows: 0,
      totalMessages: 0,

      extraSearchParams: {users: [], senderType: ""},
    }
  }
}

</script>