<template>
  <div>

    <send-preview
        :planner-type="planner_type"
        :sending-executing="sendingExecuting"
        @commitSend="commitSend()"
        ref="sendPreview"
    />
    <el-row style="margin-bottom: 20px; background-color: #def3e2; border-left: 5px solid #23b03c; padding: 20px"
            v-show="$store.getters.isShortReg"
    >
      <el-col :span="24">
        <h3>Тестовый доступ</h3>
        <p>На данном этапе Вы можете ознакомиться с возможностями платформы,
          но отправлять смс можете только на свой номер телефона.<br/>
          Для полноценного использования необходимо завершить регистрацию. </p>
        <el-button type="danger" @click="$router.push({path: '/cab/finalizeRegistration'})">
          Продолжить регистрацию
        </el-button>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 20px; background-color: #def3e2; border-left: 5px solid #23b03c; padding: 20px"
            v-show="!$store.getters.isShortReg && !$store.getters.offerAccepted"
    >
      <el-col :span="24">
        <h3>Подтверждение договора оферты</h3>
        <p>Подтвердите договор <a target="_blank" href="https://web.smsgold.ru/offer">оферты</a> банковским переводом на
          любую сумму.</p>
        <el-button type="danger" @click="$router.push({path: '/cab/finance/operations'})">
          Сформировать счёт
        </el-button>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 20px; background-color: #def3e2; border-left: 5px solid #23b03c; padding: 20px"
            v-show="namesLoaded && !(namesCount > 0)"
    >
      <el-col :span="24">
        <h3>Имя отправителя для отправки СМС</h3>
        <p>На данном этапе вы не можете отправлять смс-сообщения.<br/>
        Чтобы иметь возможность отправлять СМС, необходимо зарегистрировать имя отправителя.</p>
        <el-button type="danger" @click="$router.push({path: '/cab/lk/sender_names'})">
          Добавить имя отправителя
        </el-button>
      </el-col>
    </el-row>

    <el-form label-position="top">
      <el-row :gutter="40">
        <el-col :xs="24" :md="12">
          <el-form-item class="bold-label  close-label" label="Канал отправки">
            <el-select v-model="channel" class="full-width" :disabled="isEditMode">
              <el-option value="sms" label="СМС">
                <font-awesome-icon icon="envelope"/> &nbsp;СМС
              </el-option>
              <el-option value="telegram" label="Telegram">
                <span style="display: flex;align-items: center">
                  <img src="@shared/assets/static_img/telegram.png" style="width: 16px"/> <span>&nbsp;Telegram</span>
                </span>
              </el-option>
              <el-option value="whatsapp" label="Whatsapp" v-if="$store.getters.user.systemId() === '10004'">
                <span style="display: flex;align-items: center">
                  <img src="@shared/assets/static_img/whatsapp.png" style="width: 16px"/> <span>&nbsp;Whatsapp</span>
                </span>
              </el-option>

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12">
          <el-form-item label="Название задачи">
            <el-input v-model="task_name"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider class="blue-divider"/>
      <sms-recepients-select
          v-show="isSms"
          :is-edit-mode="isEditMode"
          @newTargets="v => dynamic.amountOfTargetsInSelectedGroups = v"
          @newExclusions="v => dynamic.amountOfExclusionsInSelectedGroups = v"
          @selectedGroups="v => dynamic.selectedContactGroups = v"
          @exclGroups="v => dynamic.exclGroups = v"
          @sexTarget="v => dynamic.sexTarget = v"
          @groupsLoaded="groupsLoaded = true"
          ref="smsRecepients"
      />
      <telegram-recepients-select
          v-show="isTelegram"
          :is-edit-mode="isEditMode"
          v-model="dynamic.telegramBot"
          @botsLoaded="botsLoaded = true"
          ref="telegramRecepients"
      />
      <whatsapp-recepients-select
          v-show="isWhatsapp"
          v-model="dynamic.whatsappChannel"
          :is-edit-mode="isEditMode"
          @channelsLoaded="waChannelsLoaded=true"
          @newTargets="v => dynamic.amountOfTargetsInSelectedGroups = v"
          @newExclusions="v => dynamic.amountOfExclusionsInSelectedGroups = v"
          @selectedGroups="v => dynamic.selectedContactGroups = v"
          @exclGroups="v => dynamic.exclGroups = v"
          @sexTarget="v => dynamic.sexTarget = v"
          @groupsLoaded="groupsLoaded = true"
          ref="whatsappRecepients"
      />
      <el-divider class="blue-divider"/>
      <el-row :gutter="40">
        <el-col :xs="24" :md="16">
          <el-row style="margin-bottom: 20px; background-color: #def3e2; border-left: 5px solid #23b03c; padding: 20px"
                  v-show="$store.getters.isShortReg && channel === 'sms'"
          >
            <el-col :span="24"><p>Если Ваш номер принадлежит одному из операторов Мегафон/Йота/Газпром Телеком, то доставляться будет наш фиксированный текст в СМС. <br/>
              Для отправки произвольного текста подтвердите аккаунт и зарегистрируйте индивидуальное имя отправителя.</p>
            </el-col>
          </el-row>


          <SmsMessageInput
              v-show="isSms"
              :selected-contact-groups="dynamic.selectedContactGroups"
              :validate-form="(p, _i) => validateForm(p, _i)"
              :test-send-opened="isVisible"
              @templatesLoaded="templatesLoaded = true"
              @namesLoaded="handleSmsSenderNamesLoadedEvent"
              @change="updatePreview"
              ref="smsMessageInput"
          />
          <TelegramMessageInput
              v-show="isTelegram && dynamic.telegramBot && dynamic.telegramBot._id"
              :selected-bot="dynamic.telegramBot"
              :is-edit-mode="isEditMode"
              @change="updatePreview"
              ref="telegramMessageInput"
          />
          <WhatsappMessageInput
              v-show="isWhatsapp && dynamic.whatsappChannel && dynamic.whatsappChannel._id"
              :selected-channel="dynamic.whatsappChannel"
              :is-edit-mode="isEditMode"
              @change="updatePreview"
              ref="whatsappMessageInput"
          />

          <h3 style="color:var(--el-color-danger)" v-show="isTelegram && (!dynamic.telegramBot || !dynamic.telegramBot._id)">Выберите бота для рассылки</h3>
        </el-col>
        <el-col :xs="24" :md="8" class="hidden-sm-and-down" style="display: flex; flex-direction: column; align-items: center">
          <PhonePreview
              :messages="previewMessages"
              v-show="isSms || (isTelegram && dynamic.telegramBot && dynamic.telegramBot._id) || (isWhatsapp && dynamic.whatsappChannel && dynamic.whatsappChannel._id)"
              :is-edit-mode="isEditMode" :display-sender="previewSenderName"
              @textClicked="handlePreviewTextClick"
          />
        </el-col>
      </el-row>
      <el-divider class="blue-divider"/>
      <Schedule
          :task="editingTask"
          :channel="channel"
          :is-edit-mode="isEditMode"
          :total-numbers="dynamic.amountOfTargetsInSelectedGroups + amountOfEnteredNumbers()"
          @typeChange="t => planner_type = t"
          ref="planerConfig"
          v-show="$store.getters.registrationFullyFinalized"
      />

      <el-row style="margin-top: 40px">
        <el-col :span="24" class="align-center">

          <div class="danger hint" style="margin-bottom: 20px" v-show="formInvalid">
            <span>{{ formValidationMessage }}</span>
          </div>

          <el-button type="success" @click="getSendPreview()"
                     :loading="sendPreviewLoading"
                     v-if="(!isEditMode || !editingTask._id) && $store.getters.registrationFullyFinalized">
            {{ planner_type === -1 ? "Отправить" : "Запланировать" }}
          </el-button>
          <el-button type="default" @click="$router.go(-1)"
                     v-if="isEditMode && editingTask._id && isSavableTask">Отменить
          </el-button>
          <el-button type="success" @click="saveTask()"
                     v-if="isEditMode && editingTask._id && isSavableTask">Сохранить
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>

import MessageTextInput from "@/components/new_send/message-text-input.component"
import moment from "moment"
import store from "@/store"
import Schedule from "@/components/new_send/schedule.component";
import {ElMessage, ElMessageBox} from 'element-plus'
import SmsRecepientsSelect from "./recepients/sms-recepients-select.component.vue";
import TelegramRecepientsSelect from "./recepients/telegram-recepients-select.component.vue";
import SendPreview from "./preview.component.vue";
import SmsMessageInput from "./message_input/sms-message-input.component.vue";
import TelegramMessageInput from "./message_input/telegram-message-input.component.vue";
import PhonePreview from "./phone-preview.component.vue";
import WhatsappRecepientsSelect from "./recepients/whatsapp-recepients-select.component.vue";
import WhatsappMessageInput from "./message_input/whatsapp-message-input.component.vue";

export default {
  name: "MainSend",
  props: ["editingTask", "isEditMode", "isVisible"],
  emits: ["dataLoaded"],
  components: {
    WhatsappMessageInput,
    WhatsappRecepientsSelect,
    PhonePreview,
    TelegramMessageInput,
    SmsMessageInput,
    SendPreview,
    TelegramRecepientsSelect,
    SmsRecepientsSelect,
    Schedule,
    MessageTextInput,
  },
  watch: {
    namesLoaded() {
      if (this.namesLoaded && this.groupsLoaded && this.templatesLoaded && this.botsLoaded && this.waChannelsLoaded) this.$emit("dataLoaded")
    },
    templatesLoaded() {
      if (this.namesLoaded && this.groupsLoaded && this.templatesLoaded && this.botsLoaded && this.waChannelsLoaded) this.$emit("dataLoaded")
    },
    groupsLoaded() {
      if (this.namesLoaded && this.groupsLoaded && this.templatesLoaded && this.botsLoaded && this.waChannelsLoaded) this.$emit("dataLoaded")
    },
    botsLoaded() {
      if (this.namesLoaded && this.groupsLoaded && this.templatesLoaded && this.botsLoaded && this.waChannelsLoaded) this.$emit("dataLoaded")
    },
    waChannelsLoaded() {
      if (this.namesLoaded && this.groupsLoaded && this.templatesLoaded && this.botsLoaded && this.waChannelsLoaded) this.$emit("dataLoaded")
    },



    editingTask(newVal) {
      if (this.isEditMode && newVal) {
        // this.loadAll()
        // console.log(newVal)
        this.channel = newVal.sendData.channel || "sms"
        this.task_name = newVal.name

        if (this.isSms) {
          this.$refs.smsRecepients.setData(newVal.sendData)
          this.$refs.smsMessageInput.proxy(
              "setTextByTemplate", {text: newVal.sendData.original_text || newVal.sendData.sms_text}
          )
          if (newVal.sendData.translit) this.$refs.smsMessageInput.proxy("forceTranslit")
          if (newVal.sendData.lifetime)
            this.$refs.smsMessageInput.setLifetime(newVal.sendData.lifetime)
          this.$refs.smsMessageInput.setSenderName(newVal.sendData.sms_sender_name)
        }
        if(this.isTelegram){
          this.dynamic.telegramBot = {_id: newVal.sendData.telegram.channelId || newVal.sendData.telegram.botId}
          this.$refs.telegramRecepients.setFilter(newVal.sendData.telegram.subscribersFilter)
          this.$refs.telegramMessageInput.setMessages(newVal.sendData.telegram.messages)
        }
        if(this.isWhatsapp){
          this.dynamic.whatsappChannel = {_id: newVal.sendData.whatsapp.channelId}
          this.$refs.whatsappRecepients.setData(newVal.sendData)
          this.$refs.whatsappMessageInput.setMessages(newVal.sendData.whatsapp.messages)
        }

      } else this.reset()
    },
    channel(){
      this.updatePreview()
    },
    "dynamic.telegramBot": function(){
      this.$nextTick().then(() => {
        this.updatePreview()
      })
    },
    "dynamic.whatsappChannel": function(){
      this.$nextTick().then(() => {
        this.updatePreview()
      })
    }
  },
  computed: {
    isSavableTask() {
      return !this.editingTask || (this.editingTask.type !== -1 && !this.editingTask.executed && !this.editingTask.processingStarted)
    },
    isSms() {
      return this.channel === "sms"
    },
    isTelegram() {
      return this.channel === "telegram"
    },
    isWhatsapp() {
      return this.channel === "whatsapp"
    },
  },
  mounted() {
    this.loadAll()
  },
  methods: {
    loadAll() {
      this.$refs.smsRecepients.loadContactGroups()
      this.$refs.telegramRecepients.loadBots()
      this.$refs.whatsappRecepients.loadChannels()
      this.$refs.whatsappRecepients.loadContactGroups()
      this.$refs.smsMessageInput.loadAll()
    },
    reset() {
      this.$refs.planerConfig.reset()
      if (this.isSms) {
        this.$refs.smsRecepients.reset()
        this.$refs.smsMessageInput.reset()
      }
      if (this.isTelegram) {
        this.$refs.telegramMessageInput.reset()
        this.$refs.telegramRecepients.reset()
      }
      if (this.isWhatsapp) {
        this.$refs.whatsappMessageInput.reset()
        this.$refs.whatsappRecepients.reset()
      }
      Object.assign(this.$data, initialState());
      this.loadAll()
    },
    amountOfEnteredNumbers() {
      if(this.isSms && this.$refs.smsRecepients)
        return this.$refs.smsRecepients.getPhones().length || 0
      if(this.isWhatsapp && this.$refs.whatsappRecepients)
        return this.$refs.whatsappRecepients.getPhones().length || 0
      return 0
    },
    async getSendPreview() {
      let payload = await this.getSendPayload()
      if (!this.validateForm(payload)) return
      this.sendPreviewLoading = true
      this.$refs.sendPreview.open(
          payload,
          this.dynamic,
          {amountOfEnteredNumbers: this.amountOfEnteredNumbers(),},
          () => {
            this.sendPreviewLoading = false
          })
    },
    validateForm(payload, ignorePhones = false) {
      if (!ignorePhones) {
        if (this.isSms && (!this.$refs.smsRecepients.getPhones().length && !payload.contact_groups.length)) {
          this.formInvalid = true
          this.formValidationMessage = "Укажите адресатов сообщенния"
          return false
        }
        if (this.isTelegram && !payload.telegram.bot.id) {
          this.formInvalid = true
          this.formValidationMessage = "Выберите бота для рассылки"
          return false
        }
      }
      if (this.isSms) {
        if ((typeof payload.sender_name === 'string' && !payload.sender_name) || (typeof payload.sender_name === 'object' && !payload.sender_name.value)) {
          this.formInvalid = true
          this.formValidationMessage = "Укажите имя отправителя"
          return false
        }
        if (!payload.text.trim()) {
          this.formInvalid = true
          this.formValidationMessage = "Заполните текст сообщения"
          return false
        }
        if (this.$refs.smsMessageInput.proxy("amountOfSymbolz") > 480) {
          this.formInvalid = true
          this.formValidationMessage = "Длина сообщения превышает 480 символов"
          return false
        }
      }
      if (this.$refs.planerConfig.inPast()) {
        this.formInvalid = true
        this.formValidationMessage = "Неверное время начала задачи"
        return false
      }
      if (this.$refs.planerConfig.incorrectEndingTime()) {
        this.formInvalid = true
        this.formValidationMessage = "Неверное время окончания задачи"
        return false
      }
      if (this.$refs.planerConfig.incorrectInterval()) {
        this.formInvalid = true
        this.formValidationMessage = "Неверное значение интервала рассылки"
        return false
      }

      this.formInvalid = false
      this.formValidationMessage = ""
      return true

    },
    async saveTask() {
      let payload = await this.getSendPayload()
      if (!this.validateForm(payload)) return
      payload.taskId = this.editingTask._id
      this.axios.post("/sms/updateTask", payload)
          .then(resp => {
            this.$store.dispatch("loadTasks")
            this.$gNotify(`Задача обновлена`, "success")
            this.$router.push({path: `/cab/messages/my_tasks`})
          })
    },
    async commitSend() {
      this.sendingExecuting = true
      let payload = await this.getSendPayload(true)

      this.axios.post("/sms/startSending", payload)
          .then(resp => {
            if (resp.data.error === "E_EMPTY_GROUPS") {
              this.sendingExecuting = false
              return this.$gNotify("Выбранные группы не содержат контактов", "error")
            }
            if (resp.data.error === "E_NO_SUITABLE_CONTACTS") {
              this.sendingExecuting = false
              return this.$gNotify("В выбраных группах нет контактов, соответствующих параметрам", "error")
            }
            this.$store.dispatch("loadTasks")
            this.sendingExecuting = false
            this.$refs.sendPreview.close()
            if (payload.planner_type === -1)
              this.$router.push({path: `/cab/messages/sending_statistics/${resp.data.__id}`})
            else {
              this.$gNotify("Задача запланирована", "success")
              this.reset()

            }
          })
    },


    async getSendPayload(clearFiles=false) {
      let {planner_type, planner_config, local_time_delivery} = this.$refs.planerConfig.getPlanerData()
      let payload = {
        channel: this.channel,
        planner_type,
        planner_config,
        task_name: this.task_name,
        local_time_delivery,
      }
      if (this.isSms) {
        let {phones, selectedGroups, exclGroups} = this.$refs.smsRecepients.getData()
        payload = Object.assign(payload, {
          sender_name: this.$refs.smsMessageInput.getSenderName(),
          phones: phones,
          text: this.$refs.smsMessageInput.proxy("getText"),
          originalText: this.$refs.smsMessageInput.proxy("getOriginalText"),
          translit: this.$refs.smsMessageInput.proxy("isTransliterated"),
          lifetime: this.$refs.smsMessageInput.getLifetime(),
          sex_target: String(this.dynamic.sexTarget),
          excl_contact_groups: exclGroups,
          contact_groups: selectedGroups,
        })
      }
      if (this.isTelegram) {
        let tgBot = this.$refs.telegramRecepients.getTargetBot()
        payload.telegram = {
          bot: tgBot,
          subscribersFilter: this.$refs.telegramRecepients.getSubscribersFilter(),
          messages: this.$refs.telegramMessageInput.getMessages()
        }
      }
      if (this.isWhatsapp) {
        let {phones, selectedGroups, exclGroups, channel} = this.$refs.whatsappRecepients.getData()
        payload = Object.assign(payload, {
          phones: phones,
          sex_target: String(this.dynamic.sexTarget),
          excl_contact_groups: exclGroups,
          contact_groups: selectedGroups,
        })
        payload.whatsapp = {
          channelId: channel._id,
          channelName: channel.name,
          messages: this.$refs.whatsappMessageInput.getMessages()
        }
      }
      return payload
    },
    updatePreview(){
      if(this.isSms && this.$refs.smsMessageInput){
        this.previewMessages = [{text: this.$refs.smsMessageInput.proxy("getText")}]
        this.previewSenderName = this.$refs.smsMessageInput.getSenderName().label
      }
      if(this.isTelegram && this.$refs.telegramMessageInput){
        this.previewMessages = this.$refs.telegramMessageInput.getMessages().map(m => {
          if(m.files && m.files.length) m.files = m.files.map(f => {
            f.architype = "tgTaskMedia"
            return f
          })
          return m
        })

        this.previewSenderName = this.$refs.telegramMessageInput.getBotName()
      }
      if(this.isWhatsapp && this.$refs.whatsappMessageInput){
        this.previewMessages = this.$refs.whatsappMessageInput.getMessages()
        this.previewSenderName = this.$refs.whatsappMessageInput.getChannelName()
      }
    },
    handlePreviewTextClick(e){
      if(this.isWhatsapp)
        this.$refs.whatsappMessageInput.onPreviewTextClicked(e)
    },
    handleSmsSenderNamesLoadedEvent(e){
      this.namesLoaded = true
      this.namesCount = e.cnt || 0
    }
  },
  data() {
    return initialState()
  },
}

function initialState() {
  return {
    channel: "sms",
    sender_name: "",
    planner_type: -1,
    task_name: `Рассылка ${moment().format("DD.MM.YYYY HH:mm")}`,

    lifetime_select: false,
    lifetime: 5,


    dynamic: {
      messageTemplates: [],
      amountOfTargetsInSelectedGroups: 0,
      amountOfExclusionsInSelectedGroups: 0,
      selectedContactGroups: [],
      exclGroups: [],
      sexTarget: "2",
      schedule: [],
      telegramBot: {_id: "", variables: [], admins: []},
      whatsappChannel: {_id: "", name: ""}
    },
    sendingExecuting: false,
    showAddExclGroups: false,
    formValidationMessage: "",
    formInvalid: false,

    namesLoaded: false,
    namesCount: 0,
    templatesLoaded: false,
    groupsLoaded: false,
    botsLoaded: false,
    waChannelsLoaded: false,
    sendPreviewLoading: false,


    previewMessages: [],
    previewSenderName: ""
  }
}

</script>

