<template>
  <div>
    <el-dialog
        v-model="authTokenManageModalVisible"
        title="Токен авторизации"
        class="modal-95-70-60"
        @close="authTokenManageModalVisible = false"
    >
      <pre style="font-weight: bold; font-size: 14px;" v-if="editingHttpAuthToken.token">{{editingHttpAuthToken.token}}</pre>
<!--      <remove-button-->
<!--          v-if="editingHttpAuthToken.token"-->
<!--          type="danger"-->
<!--          :table-button="true"-->
<!--          :loading="revokingToken"-->
<!--          :confirm-message="'Вы уверены, что хотите сбросить токен авторизации?\n'+-->
<!--'После сброса текущий токен перестанет работать, это действие невозможно отменить!'"-->
<!--          @confirm="revokeAuthToken"-->
<!--          style="margin-top: 50px"-->
<!--      >-->
<!--        Сбросить токен-->
<!--      </remove-button>-->
      <el-button type="success" v-else @click="revokeAuthToken">Сгенерировать токен</el-button>
    </el-dialog>

    <el-dialog
        v-model="createUserFormVisible"
        :title="addEditTitle"
        class="modal-95-70-60"
        @close="changePasswordRequested = false"
    >
      <el-tabs v-model="activeTab" class="inside-tabs">
        <el-tab-pane label="Пользовательские данные" name="first">
          <el-form :model="editingSubuser" :rules="formRules" ref="subuserForm"
                   label-position="top">

            <el-form-item prop="role" label="Роль" v-show="!isEditing || !editingUserIsPrimary">
              <el-select v-model="editingSubuser.role" style="width: 100%" data-test="roleSelect">
                <el-option
                    v-for="(role, i) in roles"
                    :key="i"
                    :label="role.title"
                    :value="role._id"
                />
              </el-select>
            </el-form-item>

            <el-form-item prop="surname" label="Фамилия">
              <el-input v-model="editingSubuser.surname" data-test="userSurname"/>
            </el-form-item>

            <el-form-item prop="name" label="Имя">
              <el-input v-model="editingSubuser.name" data-test="userName"/>
            </el-form-item>

            <el-form-item prop="patronymic" label="Отчество">
              <el-input v-model="editingSubuser.patronymic" data-test="userPatronymic"/>
            </el-form-item>

            <el-form-item prop="phone" label="Телефон">
              <el-input v-model="editingSubuser.phone" data-test="userPhone"/>
            </el-form-item>

            <el-form-item prop="email" label="E-mail">
              <el-input v-model="editingSubuser.email" data-test="userEmail"/>
            </el-form-item>

            <el-divider v-show="isEditing"></el-divider>
            <el-button text v-show="isEditing" @click="changePasswordRequested = !changePasswordRequested"
                       style="margin-bottom: 22px">Сменить пароль
            </el-button>
            <div v-show="changePasswordRequested || !isEditing">

              <el-form-item prop="password" label="Пароль">
                <el-input v-model="editingSubuser.password" type="password" show-password
                          placeholder="**************" data-test="userPassword">
                  <template #append>
                    <el-button @click="autogeneratePassword">Сгенерировать</el-button>
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item prop="passwordConfirm" label="Подтвердите пароль">
                <el-input v-model="editingSubuser.passwordConfirm" type="password" show-password
                          placeholder="**************" data-test="userPasswordConfirm"/>
              </el-form-item>

<!--              <el-form-item label="Выслать учётную запись">-->
<!--                <el-checkbox-group v-model="sendProfileBy">-->
<!--                  <el-checkbox label="email">по E-mail</el-checkbox>-->
<!--                  <el-checkbox label="sms">по СМС</el-checkbox>-->
<!--                </el-checkbox-group>-->
<!--              </el-form-item>-->

            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Ограничения доступа" name="second">
          <LoginRestrictions
              ref="loginRestrictions"
          />
        </el-tab-pane>
      </el-tabs>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createUserFormVisible = false">Отмена</el-button>
          <el-button type="success" :loading="subuserSaveLoading"
                     @click="saveSubuser()" data-test="saveUser">Сохранить</el-button>
        </span>
      </template>
    </el-dialog>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <TableSpinner :loading="subusersLoading">
            <el-checkbox v-model="showLockedUsers" data-test="showBlockedUsers">Показывать заблокированных
              ({{ lockedUsersCount }})
            </el-checkbox>

            <el-table :data="subusersFiltered" style="width: 100%">
              <el-table-column prop="system_id" label="Логин" width="100"/>
              <el-table-column label="Ф.И.О" min-width="250">
                <template #default="scope">
                  {{ scope.row.info.lastname }}&nbsp;{{ scope.row.info.firstname }}&nbsp;{{ scope.row.info.patronymic }}
                  <br v-if="scope.row.active === 0"/>
                  <span style="color: red" v-if="scope.row.active === 0">(пользователь заблокирован)</span>
                </template>
              </el-table-column>
              <el-table-column prop="info.phone" label="Телефон" min-width="120"/>
              <el-table-column prop="info.email" label="E-mail" min-width="180"/>
              <el-table-column label="Роль" min-width="250">
                <template #default="scope">
                  {{ getRoleName(scope.row.privilege_role) }}
                </template>
              </el-table-column>
              <el-table-column label="" min-width="150">
                <template #default="scope">
                  <ActionsDropdown :data-test="`userActions-${scope.row.system_id}`">
                    <ActionButton icon="pencil-alt" @click="showEditForm(scope.row)"
                                  :data-test="`userActions-edit-${scope.row.system_id}`">Изменить
                    </ActionButton>
                    <ActionButton icon="lock" @click="showEditAuthTokenModal(scope.row)">Токен авторизации
                    </ActionButton>
                    <ActionButton icon="copy" @click="showEditForm(scope.row, false)">Создать по образцу</ActionButton>
                    <ActionButton icon="ban" @click="blockUser(scope.row)"
                                  v-if="scope.row.active === 1 && scope.row.system_id.includes('.')"
                                  :data-test="`userActions-block-${scope.row.system_id}`">Заблокировать
                    </ActionButton>
                    <ActionButton icon="check-circle" @click="unblockUser(scope.row)"
                                  v-if="scope.row.active === 0 && scope.row.system_id.includes('.')"
                                  :data-test="`userActions-unblock-${scope.row.system_id}`">Разблокировать
                    </ActionButton>
                    <ActionButton icon="sign-out-alt"  @click="logoutEverywhere(scope.row)"
                                  v-if="$store.getters.isSurrogateLogin"
                                  >Выйти везде
                    </ActionButton>
                    <ActionButton icon="times" type="remove" @confirm="removeUser(scope.row)"
                                  v-if="scope.row.system_id.includes('.')"
                                  :data-test="`userActions-remove-${scope.row.system_id}`">Удалить
                    </ActionButton>
                  </ActionsDropdown>

                </template>
              </el-table-column>
            </el-table>
          </TableSpinner>
          <el-button type="success" plain @click="showAddUserForm()" style="margin-top: 40px" data-test="addUser">
            <font-awesome-icon icon="plus" class="el-icon--left"></font-awesome-icon>
            Добавить пользователя
          </el-button>


        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import LoginRestrictions from "@/components/lk/restrictions.component";
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component";
import ActionButton from "@shared/components/layout/actionButton.component";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: "AccountUsers",
  components: {
    RemoveButton,
    LoginRestrictions,


    ActionsDropdown,
    ActionButton
  },
  mounted() {
    this.loadSubusers()
  },
  watch:{
    changePasswordRequested(v){
      if(!v){
        this.editingSubuser.password = ""
        this.editingSubuser.passwordConfirm = ""
      }
    }
  },
  computed: {
    addEditTitle() {
      return this.editingSubuser._id ? 'Редактировать пользователя' : 'Добавить пользователя'
    },
    lockedUsersCount() {
      return this.subusers.filter(u => u.active === 0).length
    },
    subusersFiltered() {
      if (this.showLockedUsers) return this.subusers
      return this.subusers.filter(u => u.active !== 0)
    },
    isEditing() {
      return Boolean(this.editingSubuser._id)
    },
    editingUserIsPrimary() {
      return this.editingSubuser.system_id && this.editingSubuser.system_id.split(".").length === 1
    },
    loginRestrictions() {
      return this.subusers.filter(s => s.ip_access.type !== -1).map(s => {
        return {
          system_id: s.system_id,
          description: s.ip_access.description,
          rule: s.ip_access
        }
      })
    },
    formRules() {
      return {
        role: [{required: !this.editingUserIsPrimary, message: "Это поле обязательно к заполнению", trigger: "change"}],
        phone: [{
          required: true,
          message: "Неверный номер телефона",
          trigger: 'change',
          validator: (_, value) => validatePhone(patchPhone(value))
        }],
        email: [{required: true, message: "Неверный E-mail адрес", trigger: 'change', type: 'email'}],
        surname: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
        name: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
        patronymic: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
        password: [
            {
              required: false,
              message: "Пароль должен содержать минимум 8 символов, одну заглавную букву, одну строчную, цифру и спецсимвол",
              trigger: 'change',
              validator: this.passwordOk
            }
        ],
        passwordConfirm: [
          {
            required: false,
            message: "Пароли не совпадают",
            trigger: 'change',
            validator: this.validatePasswordMatch
          }
        ],

      }
    }
  },
  methods: {
    getRoleName(roleId) {
      return (this.roles.find(r => r._id === roleId) || {}).title || ""
    },
    loadSubusers() {
      this.subusersLoading = true
      this.axios.get("/user/getMySubusers")
          .then(resp => {
            this.subusers = resp.data.sort((s1, s2) => s1.system_id === s2.system_id ? 0 : s1.system_id.indexOf('.') < 0 ? -1 : s2.system_id.indexOf('.') < 0 ? 1 : Number(s1.system_id.split('.')[1]) - Number(s2.system_id.split('.')[1]))
            console.log(this.subusers)
            this.subusersLoading = false
          })
      this.axios.get("/user/getMyRoles")
          .then(resp => {
            this.roles = resp.data
          })
    },
    async showEditForm(user, setId = true) {
      if (setId)
        this.editingSubuser._id = user._id
      else
        this.editingSubuser._id = ""

      this.formRules.password[0].required = !setId
      this.editingSubuser.role = user.privilege_role
      this.editingSubuser.surname = user.info.lastname
      this.editingSubuser.name = user.info.firstname
      this.editingSubuser.patronymic = user.info.patronymic
      this.editingSubuser.phone = user.info.phone
      this.editingSubuser.email = user.info.email
      this.editingSubuser.system_id = user.system_id

      this.editingSubuser.password = ""
      this.editingSubuser.passwordConfirm = ""
      this.createUserFormVisible = true
      await this.$nextTick()
      this.$refs.loginRestrictions.setRules(user.ip_access)
      this.$refs.subuserForm.clearValidate(["role", "surname", "name", "patronymic", "phone", "email", "password", "passwordConfirm"])

    },
    async showAddUserForm() {
      this.formRules.password[0].required = true
      this.editingSubuser._id = ""
      this.editingSubuser.role = ""
      this.editingSubuser.surname = ""
      this.editingSubuser.name = ""
      this.editingSubuser.patronymic = ""
      this.editingSubuser.phone = ""
      this.editingSubuser.email = ""
      this.editingSubuser.password = ""
      this.editingSubuser.passwordConfirm = ""
      this.editingSubuser.system_id = ""
      this.createUserFormVisible = true
      await this.$nextTick()
      this.$refs.subuserForm.clearValidate(["role", "surname", "name", "patronymic", "phone", "email", "password", "passwordConfirm"])
    },
    saveSubuser() {
      this.$refs.subuserForm.validate()
          .then(v => {
            if (v) {
              this.subuserSaveLoading = true
              let loginRestriction = this.$refs.loginRestrictions.getRules()
              this.axios.post("/user/saveSubuser", {
                data: {
                  ...this.editingSubuser,
                  sendProfileBy: this.sendProfileBy,
                  loginRestriction
                }
              })
                  .then(resp => {
                    this.subuserSaveLoading = false
                    if (resp.data.error === "duplicateEmail")
                      return this.$gNotify("Такой email уже используется", "error")

                    if (resp.data.error === "duplicatePhone")
                      return this.$gNotify("Такой  номер телефона уже  используется", "error")

                    this.createUserFormVisible = false
                    this.$gNotify(`Пользователь ${this.editingSubuser._id ? 'обновлён' : 'добавлен'}`, "success")
                    this.loadSubusers()
                  })
            }
          })
          .catch(console.error)


    },
    validatePasswordMatch(_, value) {
      return value === this.editingSubuser.password
    },
    passwordOk(_, value){
      return !this.changePasswordRequested || (
          value.trim().length >= 8 && /(?=(.*[0-9]){1,})(?=(.*[a-zа-яё]){1,})(?=(.*[A-ZА-ЯЁ]){1,})(?=(.*[^0-9a-zа-яёA-ZА-ЯЁ]){1,})/.test(value)
      )
    },
    autogeneratePassword() {
      let digits = "0123456789"
      let chars = "abcdefghijklmnopqrstuvwxyz"
      let spec = "!@#$%^&*()_"
      let rV = (a) => a[Math.floor(Math.random() * a.length)]
      let pwd = [
          rV(digits),
          rV(digits),
          rV(chars),
          rV(chars),
          rV(chars),
          rV(chars),
          rV(chars),
          rV(chars.toUpperCase()),
          rV(chars.toUpperCase()),
          rV(chars.toUpperCase()),
          rV(spec),
          rV(spec)
      ].map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value).join("")

      // let pwd = Math.random().toString(36).slice(2, 16)
      this.editingSubuser.password = String(pwd)
      this.editingSubuser.passwordConfirm = String(pwd)
    },
    blockUser(user) {
      this.axios.post("/user/block", {userId: user._id})
          .then(resp => {
            this.$gNotify(`Пользователь заблокирован`, "success")
            this.loadSubusers()
          })
          .catch(console.error)
    },
    unblockUser(user) {
      this.axios.post("/user/unblock", {userId: user._id})
          .then(resp => {
            this.$gNotify(`Пользователь разблокирован`, "success")
            this.loadSubusers()
          })
          .catch(console.error)
    },
    removeUser(user) {
      this.axios.delete("/user/remove", {params: {userId: user._id}})
          .then(resp => {
            this.$gNotify(`Пользователь удалён`, "success")
            this.loadSubusers()
          })
          .catch(console.error)
    },
    showEditAuthTokenModal(user){
      this.editingHttpAuthToken.userId = user._id
      this.editingHttpAuthToken.token = user.httpAuthToken || ""
      this.authTokenManageModalVisible = true
    },
    revokeAuthToken(){
      this.revokingToken = true
      this.axios.post("/user/revokeHttpToken", {userId: this.editingHttpAuthToken.userId})
          .then(resp => {
            this.$gNotify(`Новый токен авторизации сгенерирован`, "success")
            this.editingHttpAuthToken.token = resp.data
            this.revokingToken = false
            this.loadSubusers()
          })
          .catch(console.error)
    },
    logoutEverywhere(user){
      this.$confirm(`Подтвердите выход на всех устройствах для пользователя ${user.system_id}`)
          .then(r => {
            if(r){
              // if(this.$store.getters.user.systemId(true) === user.system_id){
              //   this.$store.dispatch("logout", {
              //     allDevices: true, callback: () => {
              //       this.$router.push({path: "/login"})
              //     }
              //   })
              // }else{
                this.axios.post("/auth/extLogout", {system_id: user.system_id})
                    .then(resp => {
                      this.$gNotify(`Все сессии пользователя ${user.system_id} прерваны`, "success")
                    })
                    .catch(e => {})
              // }
            }
          })
          .catch(e => {})
    }
  },
  data() {
    return {
      subusers: [],
      roles: [],
      // synonims: [],
      sendProfileBy: ["email"],
      showLockedUsers: false,
      createUserFormVisible: false,
      // synonimFormVisible: false,
      editingSubuser: {
        system_id: "",
        _id: "",
        role: "",
        surname: "",
        name: "",
        patronymic: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirm: "",

      },
      // userForSynonim: "",
      // newSynonim: "",
      changePasswordRequested: false,
      subuserSaveLoading: false,
      subusersLoading: false,
      activeTab: "first",


      authTokenManageModalVisible : false,
      editingHttpAuthToken : {
        token : "",
        userId: ""
      },
      revokingToken: false
    }
  }
}
</script>